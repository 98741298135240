//header
import '../components/zv/select-location/component';
import '../components/zv/class-toggle/component';
import '../components/zv/back-to-top/component';
import '../components/zv/dialog/component';
import '../components/zv/gallery/component';

import '../components/zv/menu-toggle/component';
import '../components/zv/header-panel/component';
import '../components/zv/section/component';
//main
import '../components/zv/share/component';
//footer
import '../components/zv/meter/component';
type resolutionRanges = {
    xs: Array<number>,
    sm?: Array<number>,
    md?: Array<number>,
    lg?: Array<number>,
    xl: Array<number>
};
// import 'bx-components/bitrix/menu/main/component';
// import 'bx-components/bitrix/menu/context/component';
// import 'bx-components/zv/iblock.elements/region_switcher/component';
